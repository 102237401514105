import React, { Suspense ,useState , useEffect} from "react";

import '../assets/scss/main.scss';
import Layout from "../landingPage/components/Layout";
import Banner from "../landingPage/Banner";
import Features from "../landingPage/Features";
import HowItWorks from "../landingPage/HowItWorks";
import AppScreens from "../landingPage/AppScreens";
import Testimonial from "../landingPage/Testimonial";
import TestimonialDetails from "../landingPage/TestimonialDetails";
import ContactUs from "../landingPage/ContactUs";
import Map from "../landingPage/Map";
import Footer from "../landingPage/Footer";
import { positions, Provider } from "react-alert";
import AlertTemplate from "../libs/AlertTemplate";
import { withPrefix } from "gatsby-link"

const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER
  };

const IndexPage: React.FC<{}> = (props) => {
    const [loading, setLoading] = useState(true);
    
    
    useEffect(() => {
        if (location.pathname === withPrefix ("/")){
            require("../assets/css/style.css")
            require("../assets/css/responsive.css")
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }
        else{
            console.log("fail")
        }
    });

    if(loading) {
        return <div className="preloader"><div className="spinner"></div></div>
    }

    return (
        <Provider template={AlertTemplate} {...options}>
            <Layout title="Grocy | Your online shop | Developed by the shop owners for the shop owners">
                <Banner />
                <Features/>
                <HowItWorks />
                <AppScreens/>
                <Testimonial/>
                <TestimonialDetails/>
                <ContactUs/>
                <Map/>
                <Footer/>
            </Layout>
        </Provider>
    );
};

export default IndexPage;
