import React, { Fragment, useEffect, useState } from 'react';
import { grocyLogo, appleStore, googlePlay } from '../../libs/images';

const NavBar = () => {
  let [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
  });

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  };

  const navbarStyles = {
    position: 'fixed',
    height: '60px',
    width: '100%',
    backgroundColor: 'grey',
    textAlign: 'center',
    transition: 'top 0.6s',
  };

  return (
    <Fragment>
      <header className="header header-home-one">
        <nav
          className={`navbar navbar-default header-navigation stricky animated ${
            scrollPosition === 0 ? '' : 'stricky-fixed slideInDown'
          }`}
        >
          <div className="thm-container clearfix">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="navbar-header">
              <a className="navbar-brand" href="#">
                <img className="grocy-logo" src={grocyLogo} alt="Grocy" />
              </a>
            </div>
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="collapse navbar-collapse main-navigation mainmenu "
              id="main-nav-bar"
            >
              <ul className="nav navbar-nav navigation-box">
                <li className="current">
                  <a href="#">Home</a>
                </li>
              </ul>
            </div>
            <div className="right-side-box">
              <span className="start-trial-copy">
                30 Days <br />
                Free Trial<span>£5/week afterward</span>
              </span>
              <a
                href="https://apps.apple.com/us/app/id1539113503#?platform=iphone"
                className="trial-btn-button"
                title="Download Grocy"
                target="_blank"
              >
                <img src={appleStore} alt="Download Grocy" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.grocy.app"
                className="trial-btn-button"
                title="Download Grocy"
                target="_blank"
              >
                <img src={googlePlay} alt="Download Grocy" />
              </a>
            </div>
          </div>
        </nav>
      </header>
    </Fragment>
  );
};

export default NavBar;
