import React, { Fragment } from 'react';
import { appleStore, googlePlay } from '../libs/images';

const HowItWorks = () => {
  return (
    <Fragment>
      <section className="faq-style-one sec-pad dark-gray-bg">
        <div className="thm-container">
          <div className="sec-title text-center">
            <span>Our three steps mantra</span>
            <h3>We make it easy. And we mean it.</h3>
          </div>
          <div className="row">
            <div className="single-faq-style-one">
              <div className="icon-box">
                <i className="fa fa-user-plus" />
              </div>
              <div className="title-box">
                <h3>Sign up and set-up</h3>
              </div>
              <p>
                Download the app and sign up to get started. Your online store
                will be live in no time with pre-loaded 75000+ products and you
                will have full control over your shop.
              </p>
            </div>
            <div className="single-faq-style-one">
              <div className="icon-box">
                <i className="fa fa-bullhorn" />
              </div>
              <div className="title-box">
                <h3>Promote your online store</h3>
              </div>
              <p>
                We are here to help you to promote your online store, you will
                access to printed and digital marketing materials to get the
                words out to your customer.
              </p>
            </div>
            <div className="single-faq-style-one">
              <div className="icon-box">
                <i className="fa fa-shopping-basket" />
              </div>
              <div className="title-box">
                <h3>Start selling</h3>
              </div>
              <p>
                Start taking order and selling through your new online shop,
                retaining loyal customers, help your community staying safe
                during the pandemic.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="cta">
              <p>
                <strong>Start 30 Days Free Trial</strong>
                <br />
                £5/week afterward
              </p>
              <div className="right-side-box">
                <a
                  href="https://apps.apple.com/us/app/id1539113503#?platform=iphone"
                  className="trial-btn-button"
                  title="Download Grocy"
                  target="_blank"
                >
                  <img src={appleStore} alt="Download Grocy" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.grocy.app"
                  className="trial-btn-button"
                  title="Download Grocy"
                  target="_blank"
                >
                  <img src={googlePlay} alt="Download Grocy" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default HowItWorks;
