import React, { Fragment } from 'react';
import Slider from 'react-slick';

const Testimonial = () => {
  const settings = {
    // className: 'center',
    // centerMode: true,
    infinite: true,
    // centerPadding: '60px',
    slidesToShow: 3,
    dots: true,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Fragment>
      <section className="testimonial-style-one sec-pad">
        <div className="thm-container">
          <div className="sec-title text-center">
            <span>User reviews</span>
            <h3>What our customers say about Grocy</h3>
          </div>

          <div className="testimonials-carousel-style-one">
            <Slider {...settings}>
              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>Less than 2 minutes to set up my shop online.</p>
                </div>
              </div>

              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>I’ve found all my products in the App.</p>
                </div>
              </div>

              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>Simple to use.</p>
                </div>
              </div>

              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>I can set my own time to deliver.</p>
                </div>
              </div>

              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>
                    My customers do not have to wait outside in a long queue.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>Cash in hand on sale.</p>
                </div>
              </div>

              <div className="item">
                <div className="single-testimonials-style-one">
                  <p>Support the local community to stay safe.</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Testimonial;
