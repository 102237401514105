import React, { Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { awsClient } from '../client/client';

// https://www.danilucaci.com/blog/validate-forms-in-react

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  message: Yup.string().required('Message is required'),
});

const ContactUs = () => {
  const alert = useAlert();

  const onSubmitHandler = async (values, { resetForm }) => {
    const { statusCode } = await awsClient('email/contact-us', values);
    if (statusCode == 200) {
      resetForm();
      alert.success('Your email has been sent successfully');
      return;
    } else {
      alert.error('The email sent failed, Pleased try again later');
      return;
    }
  };

  return (
    <Fragment>
      <section className="contact-page-content sec-pad">
        <div className="thm-container">
          <div className="row">
            <div className="col-md-5">
              <div className="contact-info">
                <span>Let's get started</span>
                <h3>Contact us</h3>
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-phone" aria-hidden="true" />{' '}
                    <a href="tel:02080508253">020 8050 8253</a>
                  </p>
                  <p>
                    <i className="fa fa-whatsapp" aria-hidden="true" />{' '}
                    <a href="https://api.whatsapp.com/send?phone=+447908482845">
                      07908 482 845
                    </a>
                  </p>
                  <p>
                    <i className="fa fa-envelope" aria-hidden="true" />{' '}
                    <a href="mailto:hello@grocy.shop">hello@grocy.shop</a>
                  </p>
                </div>
                <div className="single-contact-info">
                  <h4>144 Beddington Lane, Croydon CR0 4TD</h4>
                  <p className="small">
                    Registered in UK, company registration no: 13176791
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  message: '',
                }}
                validationSchema={SignupSchema}
                onSubmit={onSubmitHandler}
              >
                {({ errors, touched }) => (
                  <Form className="contact-form">
                    <Field
                      name="name"
                      placeholder="Your name"
                      className={
                        errors.name && touched.name ? 'input-error' : ''
                      }
                    />
                    <ErrorMessage name="name">
                      {(msg) => <p className="error-message">{msg}</p>}
                    </ErrorMessage>
                    <Field
                      name="email"
                      placeholder="Email address"
                      className={
                        errors.email && touched.email ? 'input-error' : ''
                      }
                    />
                    <ErrorMessage name="email">
                      {(msg) => <p className="error-message">{msg}</p>}
                    </ErrorMessage>
                    <Field
                      name="message"
                      component="textarea"
                      placeholder="Write message here"
                      className={
                        errors.message && touched.message ? 'input-error' : ''
                      }
                    />
                    <ErrorMessage name="message">
                      {(msg) => <p className="error-message">{msg}</p>}
                    </ErrorMessage>
                    <button type="submit" className="hvr-sweep-to-right">
                      Send Message
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ContactUs;
