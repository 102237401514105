import React, { Component } from 'react';
import Slider from 'react-slick';
import { appScreen1, appScreen2, appScreen3 } from '../libs/images';

export default () => {
  const settings = {
    // className: 'center',
    // centerMode: true,
    // infinite: true,
    // centerPadding: '60px',
    slidesToShow: 1,
    dots: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section className="app-screenshot-style-one gray-bg sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>The Grocy look</span>
          <h3>Amazing shop owner and customer experiences</h3>
        </div>
        <div className="app-screenshot-carousel-one">
          <Slider {...settings}>
            <div className="item">
              <img src={appScreen2} alt="Customer store" />
            </div>
            <div className="item">
              <img src={appScreen3} alt="Store owner dashboard" />
            </div>
            <div className="item">
              <img src={appScreen1} alt="Store products" />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};
