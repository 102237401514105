import React, { Fragment } from 'react';
import { googlePlay, bannerMoc1, appleStore } from '../libs/images';

const Banner = () => {
  return (
    <Fragment>
      <section className="banner-style-one gray-bg">
        <div className="thm-container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner-content">
                <h3>
                  <span>Grocy.</span> Your online shop.
                </h3>
                <p>
                  Setting up your online store is quick and easy with Grocy,
                  with no commission or long-term commitments.
                </p>
                <p>
                  We crafted a world class shopping platform for local shop
                  owners and customers to interact with ease and we will help
                  you to get online in no time.
                </p>
                <p>
                  <strong>Start 30 Days Free Trial</strong>
                  <br />
                  £5/week afterward
                </p>
                <ul>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" /> No
                    contracts
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" /> No
                    commission
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" /> Cancel at
                    any time
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" /> No credit
                    card needed
                  </li>
                </ul>
                <div className="right-side-box hero-cta">
                  <div className="buttons">
                    <a
                      href="https://apps.apple.com/us/app/id1539113503#?platform=iphone"
                      className="trial-btn-button"
                      title="Download Grocy"
                      target="_blank"
                    >
                      <img src={appleStore} alt="Download Grocy" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.grocy.app"
                      className="trial-btn-button"
                      title="Download Grocy"
                      target="_blank"
                    >
                      <img src={googlePlay} alt="Download Grocy" />
                    </a>
                  </div>
                </div>
                <p className="tagline">
                  <span>Developed by shop owners </span>for the shop owners
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                className="grocy-hero-phone"
                src={bannerMoc1}
                alt="Awesome Image"
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Banner;
