import React, { Fragment } from 'react';

const Features = () => {
  return (
    <Fragment>
      <section className="features-style-one gray-bg sec-pad">
        <div className="thm-container">
          <div className="sec-title text-center">
            <span>What's in the box</span>
            <h3>Packed with features.</h3>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-barcode icofont" />
                </div>
                <h3>
                  Barcode
                  <br />
                  scanner
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-database-add icofont" />
                </div>
                <h3>
                  75000+ products in
                  <br />
                  our database
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-bullhorn icofont" />
                </div>
                <h3>
                  Send instant
                  <br /> offer notifications
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-shopping-cart icofont" />
                </div>
                <h3>
                  Own
                  <br /> online shop
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-ui-add icofont" />
                </div>
                <h3>
                  Add your
                  <br /> own products
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-fast-delivery icofont" />
                </div>
                <h3>
                  Click &amp; Collect
                  <br /> or delivery
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-credit-card icofont" />
                </div>
                <h3>
                  Cash, card, mobile
                  <br /> and online payment
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-features-style-one hvr-bounce-to-bottom text-center">
                <div className="icon-box">
                  <i className="icofont-bank-alt icofont" />
                </div>
                <h3>
                  Paid directly
                  <br /> into your bank
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Features;
