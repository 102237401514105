import React, { Fragment } from 'react';

const Map = () => {
  return (
    <Fragment>
      <div className="map-responsive">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2489.97238787762!2d-0.1411119481910405!3d51.385185527351794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876070094d0bb09%3A0x5bd6200c0a9aeadd!2s144%20Beddington%20Ln%2C%20Croydon%20CR0%204TD!5e0!3m2!1sen!2suk!4v1616271413962!5m2!1sen!2suk"
          width={600}
          height={585}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
      </div>
    </Fragment>
  );
};

export default Map;
