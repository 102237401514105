export default async (query) => {
  const idToken = localStorage.getItem('idToken');
  return fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-hasura-admin-secret': process.env.GATSBY_X_HASURA_ADMIN_SECRET, // Please setup Authorization token and remove admin secret
      // Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(query),
  }).then((response) => response.json());
};

export const publicClient = (query) => {
  return fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Hasura-Role': 'anonymous',
    },
    body: JSON.stringify(query),
  }).then((response) => response.json());
};

export const awsClient = (route, query) => {
  return fetch(`${process.env.AWS_API}/${route}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'twWPCbRYUR6YwJIJUXHTK9AW9p7vJGF09FW3Zpmt',
    },
    body: JSON.stringify(query),
  }).then((response) => response.json());
};
