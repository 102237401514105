import React, { Fragment } from 'react';
import NavBar from './NavBar';
import { Helmet } from 'react-helmet';
import {
  appleTouchIcon,
  favicon,
  favicon16,
  favicon32,
} from '../../libs/images';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default ({ children, title }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="manifest" href="img/favicon/site.webmanifest" />
        <link rel="shortcut icon" href={favicon} />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#4CBD41" />
        <body className="shop-owner" />
      </Helmet>
      <NavBar />
      {children}
    </Fragment>
  );
};
