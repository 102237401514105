import React, { Fragment } from 'react';

const TestimonialDetails = () => {
  return (
    <Fragment>
      <section className="testimonial-style-one app-screenshot-style-one gray-bg sec-pad about">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="thm-container">
                <div className="sec-title text-center">
                  <span>Grocy</span>
                  <h3>About us</h3>
                </div>
                {/* /.testimonials-carousel-style-one */}
              </div>
              {/* /.thm-container */}
              <p className="big">
                At Grocy, our vision is to create software solutions that drives
                businesses and technology forward. Our cloud-based software
                platform deliver unparalleled competitive advantage to small
                shop owner to create and manage their online store.
              </p>
            </div>
          </div>
          {/* /.col-md-3 */}
        </div>
        {/* /.sec-title text-center */}
      </section>
    </Fragment>
  );
};

export default TestimonialDetails;
