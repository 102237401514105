import React, { Fragment } from 'react';
import { gbFlag } from '../libs/images';

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer-style-one">
        <div className="footer-bottom text-center">
          <div className="thm-container">
            <img src={gbFlag} alt="Made in Britain" />
            <p>
              © 2021 Copyrights.{' '}
              <a
                href="https://grocy.shop/terms-and-conditions/"
                target="_blank"
              >
                Terms &amp; Conditions{' '}
              </a>
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
